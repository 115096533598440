import { AxiosError } from "axios";
import { alert, alertLoading, alertRedirect } from "../@core/components/alert";
import { useNavigate } from "react-router-dom";
import { formatErrorIsObject, getUserData } from "./Utils";
import Swal from "sweetalert2";
import { useGetResendMailVerify } from "./hooks/auth/useGetAuth";
import { getRequest } from "../services/api";

export const ErrorHandling = (error: AxiosError) => {
  console.log(error);
  const user = getUserData();
  if (error.response?.status === 0 || error.code == "ERR_NETWORK") {
    alert(
      "Servidor da api fora do ar, Entrar em contato com o administrador. Tente novamente mais tarde",
      "error"
    );
    return "";
  }
  if (error.response?.status === 404) {
    if (error.response.data == "usuario não encontrado") {
      localStorage.removeItem("@realitymed.user");
      localStorage.removeItem("@realitymed.token");
      alertRedirect(
        "Usuário não encontrado, redirecionado para login",
        "error",
        "/login"
      );
      return "";
    }
  }
  if (error.response?.status === 401) {
    if (error.response.data == "Acesso não autorizado") {
      localStorage.removeItem("@realitymed.user");
      localStorage.removeItem("@realitymed.token");
      alertRedirect(
        "Identificamos acesso simultâneo nessa conta. Você será redirecionado para o login.",
        "info",
        "/login"
      );
      return "";
    }
    if (error.response.data == "Em manutenção") {
      localStorage.removeItem("@realitymed.user");
      localStorage.removeItem("@realitymed.token");
      window.location.href = "/manutencao";
      return "";
    }
  }
  if (
    error.response?.status === 500 &&
    error.response?.data == "Your email address is not verified."
  ) {
    Swal.fire({
      text: "Desculpe! Vimos que seu email não foi verificado. Por favor clique em reenviar email de verificação",
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Reenviar email de verificação",
    }).then((result) => {
      if (result.isConfirmed) {
        alertLoading("Enviando email...", "info");
        getRequest(`mail/resend/${user.id}`).then((response) => {
          alertRedirect(
            "Email enviado com Sucesso. Em instante você sera redirecionado para tela de login",
            "success",
            "/login"
          );
        });
      }
    });
    return;
  }
  if (typeof error?.response?.data == "object") {
    if (error?.response?.status == 422) {
      alert(
        formatErrorIsObject(error?.response?.data) ?? "Algo deu errado",
        "error",
        error?.response?.status
      );
    } else {
      alert(JSON.stringify(error?.response?.data), "error", error.status);
    }
    return "";
  } else {
    if ((error.response?.data as any)?.includes("error_messages")) {
      alert(
        "Não foi possível efetuar o cadastro, pois o pagseguro estar com instabilidade no momento. Tente novamente mais tarde, caso persista entre em contato com o administrador",
        "error"
      );
      return "";
    }
    alert(
      error?.response?.data == "" || error?.response?.data == undefined
        ? JSON.stringify(error)
        : error?.response?.data,
      "error",
      error?.response?.status
    );
    return "";
  }
};
