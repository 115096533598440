// ** Logo
import logo from "../../../assets/images/logo/logo_reality_hor_cor.svg";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img
        className="fallback-logo"
        src={logo}
        alt="logo"
        width={300}
        height={100}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
