import { Ability } from "@casl/ability";
import { initialAbility } from "./initialAbility";
import { getPermissionsUser } from "../../utility/Utils";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem("@realitymed.user") as any);
const existingAbility = userData
  ? getPermissionsUser(userData.roles, userData.permissions)
  : null;

export default new Ability(existingAbility || initialAbility);
