// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { store } from "./redux/store";
import { Provider } from "react-redux";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import { ToastPosition, Toaster } from "react-hot-toast";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Hot Toast Styles
import "./@core/scss/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./index.scss";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorHandling } from "./utility/ErrorHandling";
import ReactGA from "react-ga";
import SentryInit from "./configs/sentry/config";
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container: any = document.getElementById("root");
const root = createRoot(container);
const toastPosition = themeConfig.layout.toastPosition as ToastPosition;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 50000, // 50s
      cacheTime: 10 * (60 * 1000), // 10 mins
      retry: (failureCount, error: any) => {
        if (failureCount <= 1 && error?.code == "ERR_NETWORK") {
          return true;
        }
        return false;
      },
      onError: (error: any) => {
        // if (error.status != 401) {
        //   if (typeof error.data == "object") {
        //     alert(
        //       formatErrorIsObject(error.data) ?? "Algo deu errado",
        //       "error"
        //     );

        //     console.log(error);
        //   } else {
        //     alert(error.data, "error");
        //   }
        // }
        ErrorHandling(error);
      },
    },
    mutations: {
      retry: (failureCount, error: any) => {
        if (
          failureCount <= 1 &&
          (error.response?.status === 0 || error.code == "ERR_NETWORK")
        ) {
          return true;
        }
        return false;
      },
    },
  },
});
import.meta.env.VITE_APP_DEV == "true"
  ? ""
  : ReactGA.initialize("G-BNW2FDRX3C", { debug: false });
SentryInit;
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <LazyApp />
              <Toaster
                position={toastPosition}
                toastOptions={{ className: "react-hot-toast" }}
              />
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
